declare global {
  interface Window {
    fbq: any
  }
}

/**
 * Initializes Facebook pixel
 * @param {string} id
 */
export const init = (id: string) => {
  if (window?.fbq && id) {
    window.fbq?.('init', id)
  }
}

/**
 * Fires a Facebook pixel page view
 * See {@link https://developers.facebook.com/docs/meta-pixel/reference#standard-events}
 *
 */
export const pageview = () => {
  if (window?.fbq) {
    window.fbq?.('track', 'PageView')
  }
}

/**
 * Fires a Facebook pixel event
 * See {@link https://developers.facebook.com/docs/meta-pixel/reference#standard-events}
 *
 * @param {string} name
 * @param {object} options
 */
export const event = (name: string, options = {}) => {
  if (window?.fbq && name) {
    window.fbq?.('track', name, options)
  }
}

export default {
  init,
  pageview,
  event,
}
