import fbq from 'libs/fbpixel'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

/**
 * Track a page view for each router transition event
 *
 * @param {string} title
 */
export const usePageView = (title: string) => {
  const router = useRouter()

  useEffect(() => {
    // This pageview only triggers on first load
    fbq.pageview()
    /**
     * Sample page object:
     *  {
     *    path: "/for-creators"
     *    title: "For Creators | Moment"
     *    url: "https://momenthousestage.com/for-creators"
     *  }
     */
    const onRouteChangeComplete = () => {
      fbq.pageview()
    }
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router.events])
}

export default usePageView
